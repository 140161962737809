import * as Style from './style'
import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { isFeatureEnabled } from '@lenses/features'
import { FEATURE_FLAGS } from '@root/constants'
import Eko from '@media/eko.svg'
import { Tooltip } from '@toriihq/design-system'
import { getSelf } from '@selectors/me'
import Analytics from '@helpers/analytics'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import { getIsSmallScreen } from '@selectors/ui'

type Corner = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

const CORNER_OFFSET_X = 20
const CORNER_OFFSET_Y = 10
const WIDGET_WIDTH = 60
const WIDGET_HEIGHT = 60

const ToriiChat = () => {
  const featureEnabled = useSelector(state => isFeatureEnabled(state, FEATURE_FLAGS.AIChat))
  const user = useSelector(getSelf)
  const isSmallScreen = useSelector(getIsSmallScreen)
  const [isOpen, setIsOpen] = useState(false)
  const [hasMessageBeenSent, setHasMessageBeenSent] = useState(false)
  const [corner, setCorner] = useState<Corner>('bottom-right')
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)

  const nodeRef = useRef(null)
  const iframeOpened = useRef(false)
  const dragRef = useRef(false)

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false)
      }
      if (event.key === 'e' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        setIsOpen(prev => !prev)
        if (!iframeOpened.current) {
          iframeOpened.current = true
        }
      }
    }

    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [isOpen])

  useEffectOnce(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type === 'CLOSE_POPUP') {
        setIsOpen(false)
      }
      if (event.data?.type === 'MESSAGE_SENT') {
        Analytics.track('First message sent', {
          email: user.email
        })
        setHasMessageBeenSent(true)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  })

  const calculateSnapPosition = useCallback((targetCorner: Corner): { x: number; y: number } => {
    const { innerWidth: winWidth, innerHeight: winHeight } = window
    let x = 0
    let y = 0

    switch (targetCorner) {
      case 'top-left':
        x = CORNER_OFFSET_X
        y = CORNER_OFFSET_Y
        break
      case 'top-right':
        x = winWidth - WIDGET_WIDTH - CORNER_OFFSET_X
        y = CORNER_OFFSET_Y
        break
      case 'bottom-left':
        x = CORNER_OFFSET_X
        y = winHeight - WIDGET_HEIGHT - CORNER_OFFSET_Y
        break
      case 'bottom-right':
      default:
        x = winWidth - WIDGET_WIDTH - CORNER_OFFSET_X
        y = winHeight - WIDGET_HEIGHT - CORNER_OFFSET_Y
        break
    }
    return { x, y }
  }, [])

  useEffect(() => {
    setPosition(calculateSnapPosition(corner))

    const handleResize = () => {
      setPosition(calculateSnapPosition(corner))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [corner, calculateSnapPosition])

  const getNearestCorner = (x: number, y: number): Corner => {
    const { innerWidth: winWidth, innerHeight: winHeight } = window
    const midX = winWidth / 2
    const midY = winHeight / 2

    if (y < midY) {
      return x < midX ? 'top-left' : 'top-right'
    } else {
      return x < midX ? 'bottom-left' : 'bottom-right'
    }
  }

  const handleDragStart = () => {
    dragRef.current = true
  }

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setIsDragging(true)
    setPosition({ x: data.x, y: data.y })
  }

  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    dragRef.current = false
    const finalX = data.x + WIDGET_WIDTH / 2
    const finalY = data.y + WIDGET_HEIGHT / 2
    const nearestCorner = getNearestCorner(finalX, finalY)
    const snappedPosition = calculateSnapPosition(nearestCorner)

    setCorner(nearestCorner)
    setPosition(snappedPosition)
  }

  const toggleWidget = (e: React.MouseEvent) => {
    if (!isDragging && e.button === 0) {
      setIsOpen(prev => !prev)
      if (!iframeOpened.current && !isOpen) {
        iframeOpened.current = true
        Analytics.track('Widget opened', {
          email: user.email
        })
      }
    }
    setTimeout(() => {
      setIsDragging(false)
    }, 200)
  }

  if (!featureEnabled || isSmallScreen) return null

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        position={position}
        onStart={handleDragStart}
        onDrag={handleDrag}
        onStop={handleDragStop}
      >
        <Style.WidgetContainer ref={nodeRef} $corner={corner} $isDragging={dragRef.current} data-corner={corner}>
          <Tooltip label='Chat with Eko (⌘E): Ask questions, get help, and get started with Torii' placement='left' hide={isOpen || dragRef.current || isDragging}>
            <Style.WidgetButton onClick={toggleWidget}>
              <Style.IconContainer>
                <img src={Eko} alt='Eko' width={45} height={45} style={{ position: 'absolute', bottom: 0 }} />
              </Style.IconContainer>
              <div style={{ position: 'absolute', inset: 0 }} />
            </Style.WidgetButton>
          </Tooltip>
          {iframeOpened.current && (
            <Style.ChatWindow $isOpen={isOpen} $corner={corner} $hasMessageBeenSent={hasMessageBeenSent}>
              <Style.Iframe src='https://chat.toriihq.com' title='Torii Chat' loading='lazy' />
            </Style.ChatWindow>
          )}
        </Style.WidgetContainer>
      </Draggable>
    </>
  )
}

export default ToriiChat
