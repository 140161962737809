import styled, { css } from 'styled-components'

type Corner = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

const WIDGET_WIDTH = 50
const WIDGET_HEIGHT = 50

export const WidgetContainer = styled.div<{
  $corner: Corner;
  $isDragging: boolean;
}>`
  position: fixed;
  inset: 0;
  width: ${WIDGET_WIDTH}px;
  height: ${WIDGET_HEIGHT}px;
  z-index: 9999;
  transition: ${({ $isDragging }) => $isDragging ? 'none' : 'transform 0.3s ease-in-out'};
  cursor: ${({ $isDragging }) => $isDragging ? 'grabbing' : 'pointer'};
`
export const WidgetButton = styled.button`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.primary};
  color: white;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`

const getChatWindowPosition = (corner: Corner) => {
  switch (corner) {
    case 'top-left':
      return css`
        top: 0;
        left: 0;
        transform-origin: top left;
      `
    case 'top-right':
      return css`
        top: 0;
        right: 0;
        transform-origin: top right;
      `
    case 'bottom-left':
      return css`
        bottom: 0;
        left: 0;
        transform-origin: bottom left;
      `
    case 'bottom-right':
    default:
      return css`
        bottom: 0;
        right: 0;
        transform-origin: bottom right;
      `
  }
}

export const ChatWindow = styled.div<{
  $isOpen: boolean;
  $hasMessageBeenSent: boolean;
  $corner: Corner;
}>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 450px;
  height: ${props => props.$hasMessageBeenSent ? 'calc(100vh - 150px)' : '440px'};
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform-origin: bottom right;
  opacity: ${props => props.$isOpen ? 1 : 0};
  visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
  transform: scale(${props => props.$isOpen ? 1 : 0.9});
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, height 0.3s ease-out, visibility 0s ${props => props.$isOpen ? '0s' : '0.2s'};
  ${({ $corner }) => getChatWindowPosition($corner)}
  @keyframes scaleIn {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.primary};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

export const IconContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
`
